.multi-select-wrapper {
  /* min-width: 300px; Set your desired width */
  min-width: -webkit-fill-available;
}

.multi-select-wrapper .multi-select {
  /* min-width: 300px; Set your desired width */
}

.multi-select-wrapper .dropdown-heading {
  min-height: 36px !important; /* Set a default height */
  height: auto;
}

.multi-select-wrapper .dropdown-heading-value {
  display: flex !important;
  flex-wrap: wrap !important; /* Allow multiple lines */
  min-height: 36px !important; /* Set a default height */
  overflow-y: auto !important; /* Add a scrollbar if needed */
  white-space: pre-wrap !important;
}

/* max-height: 100px !important;  Set a maximum height */
.MuiToggleButton-root.Mui-selected {
  background-color: rgba(26, 115, 232, 0.38) !important;
}
