input.inputBox {
  color: #6c757d;
  border-color: rgba(108, 117, 125, 0.5);
  border-radius: 4px;
  border-style: solid;
  border-width: thin;
  padding: 5px;
  text-align: center;
}

.rdrInputRanges .rdrInputRange:nth-child(2) {
  display: none;
}
