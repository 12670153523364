.button-container button {
  border: 0;
  cursor: pointer;
  vertical-align: middle;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.02857em;
  width: 100%;
  color: white;
  background: #1a73e8;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  min-height: 2rem;
  padding: 0.375rem 1rem;
}
