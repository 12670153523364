.search__input__react__table {
  width: 200px;
  height: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  flex: 1 1 auto;
  min-width: 0;
  margin-bottom: 0;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.175rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: visible;
  margin: 0;
  font-family: inherit;
  box-sizing: border-box;
}

.search__input__react__table::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #696969;
  opacity: 1; /* Firefox */
}
